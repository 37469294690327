<template>
  <div v-if="loading" class="flex justify-center items-center">
    <t-loader extraClass="border-primary-700 w-8 h-8" />
  </div>
  <div v-else>
    <div class="px-5 md:px-20 mx-auto my-10" style="background-color: none">
      <h1 class="text-5xl text-center my-10">{{ title }}</h1>

      <br />

      <p class="text-center lg:text-right text-sm md:text-lg" v-if="created">
        <strong>Publicado:</strong>
        {{
          new Intl.DateTimeFormat("es-ES", {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true
          }).format(new Date(created + "Z"))
        }}
      </p>

      <br />

      <div
        class="max-w-md mx-auto rounded-xl shadow-md overflow-hidden md:max-w-2xl"
      >
        <div class="md:flex">
          <div class="md:shrink-0">
            <img
              v-if="url_picture"
              class="h-48 w-full object-cover md:h-full md:w-48"
              :src="url_picture"
              alt="Header picture"
            />
          </div>
        </div>
      </div>

      <hr class="mt-5 mb-1" />

      <br />

      <div
        class="ql-editor"
        style="max-width: 95% !important"
        v-html="content"
      ></div>
    </div>

    <br />

    <div v-if="reporter">
      <p class="mb-2 text-center">
        <strong>Autor de esta noticia</strong>
      </p>

      <img
        v-if="reporter.profile_picture"
        class="text-center w-16 h-16 object-contain rounded-full"
        :src="reporter.profile_picture"
        alt="Profile image"
      />

      <p class="mt-2 text-center">
        {{ reporter.first_name + " " + reporter.last_name }}
      </p>
    </div>
  </div>
</template>

<script>
document.querySelectorAll("pre.ql-syntax").forEach((block) => {
  hljs.highlightBlock(block)
})

export default {
  name: "NoticeNew",
  components: {},
  data() {
    return {
      content: "<h1 style='text-align: center'>Cargando ...</h1>",
      created: "",
      reporter: null,
      title: "",
      url_picture: null,
      editorOption: {},
      loading: true,
      error: ""
    }
  },
  mounted() {
    this.showNotice()
  },
  methods: {
    showNotice() {
      var token = this.$route.params.slug
      this.$store
        .dispatch("global/getItems", {
          route: "notice/token/" + token,
          noPaginate: true
        })
        .then((response) => {
          this.data = response.data
          this.content = response.data.body
          this.created = response.data.created_at
          this.title = response.data.title
          this.url_picture = response.data.url_picture
          this.reporter = response.data.reporter

          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    }
  }
}
</script>
<style scoped>
img {
  width: 100% !important;
}
.quill-editor iframe {
  pointer-events: none;
}
.ql-video {
  width: 90%;
}
</style>
